@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;
      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;
      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;
      --radius: 0.5rem;
    
      --black-primary: #111214;
      --light-grey: #c3c3c3;
      --white: white;
      --black-secondary: #18191c;
      --grey: #393a3d;
      --turquoise: #4bc0c8;
      --orchid: #c779d0;
      --white-smoke: #e9e9e9;
      --transparent: rgba(255, 255, 255, 0);
      --aquamarine\<deleted\|variable-34a02cca\>: #91f5bc;
  }
  
    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;
      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;
      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;
      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 11.2%;
      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;
      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;
      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;
      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 212.7 26.8% 83.9;
    }
  }

  h3{
    font-family: 'Ubuntu', Helvetica, sans-serif;
  }
  body{
    background-color: var(--black-primary)!important;
  }
  
  .menu-lateral{
    background-color: #111423!important;
    height: vh-100;
  }
  
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: #293055;
    padding: 50px;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color:white;
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }
  .separador{
    margin-top: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(107, 107, 107);
  }
  .separador-blanco{
    margin-top: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(255, 255, 255);
  }
  
  label{
    color:white;
    font-weight: 200;
    
  }
  
  .btn{
    background-color: white!important;
    color:black!important;
  }
  
  .btn-primary{
    color:black;
  }
  .boton{
    color:white;
    background-color:#111423!important;
    padding:13px;
    width: fit-content;
    min-width: 140px;
    text-align: center;
    text-decoration: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 13px!important;
    cursor: pointer;
  }
  .boton:hover{
    background-color:#3e4879!important;
    color:#0c0e1b;
  }
  .negro{
    background-color: #000000;
  }
  .menu-lateral{
    background-color: #000000;
    top: 0;
    left: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right:0px!important;
    border-top-right-radius: 0px;
    padding-left: 60px;
  }
  .blanco{
    background-color: white;
  }
  
  .panel-derecho{
    background-color:#0c0e1b;
   
    padding-bottom: 3rem !important;
    height: 100vh;
  }
  .botones-menu-lateral{
    padding:15px;
    padding-right: 0px;
    background-color:#0c0e1b;
    border-radius: 20px;
    color:white;
    padding-left:30px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-top:10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
  }
  .botones-menu-lateral:hover{
  
    margin-left: 20px;
  
  }
  .eliminar{
    display: inline;
  }
  
  
  .nav-header{
    background-color: #111423!important;
  }
  
  input,textarea,select{
    background-color:#111423!important;
    border: solid 1px #293055!important;
    color: white!important;
  }
  .form-check-input
  {
    cursor: pointer;
  }
  .form-check-input:checked{ 
  
    background-color:green!important;
    border:inherit!important;
    color:inherit!important;
  
   }
  ::placeholder {
    color: rgb(78, 78, 78)!important;
    opacity: 1; /* Firefox */
  }
  
  body .modal-content{
    background-color: #1a1e33;
    color:white;
  
  }
  body .modal-content .modal-header{
    border-bottom: solid 1px #293055;
  }
  
  body .modal-content .modal-footer{
    border-top: solid 1px #293055;
  }
  body .btn-close
  {
    color:white!important;
  }
  body .form-label {
    padding-top: 12px;
    margin-bottom: 0.5rem;
  }
  
  body .navbar-collapse  .dropdown .dropdown-menu{
    background-color: #1a1e33;
    color:white!important;
    border: solid 1px #293055;
        left: -107px!important;
      margin-top: 8px!important;
      font-size: 13px;
  
  
  }
  body .navbar-collapse  .dropdown .dropdown-menu .dropdown-item{
    color:white;
  }
  body .navbar-collapse  .dropdown .dropdown-menu .dropdown-item:hover{
    background-color: #293055;
    color:white;
  }
  
  body .avatar-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    border:solid 3px white;
    cursor: pointer;
  }
  
  body .card-title{
    color:white;
    font-weight: bold;
    font-size: 20px;
    background-color: #293055;
    border-radius: 10px;
    padding:10px;
  }
  body .card-text{
    color:white;
    font-weight: bold;
    font-size: 12px;
    background-color: #293055;
    border-radius: 10px;
    padding:10px;
  }
  body .card{
    background-color: #1a1e33;
  }
  
  body .resultaado{
    margin-top: 30px;
  }
  
  body .separador{
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: solid 1px #293055;
  }
  
  .centrar-form{
    margin-top: 35px;
  }
  body .card-body{
    color:white;
  }
  body .list-group{
    background-color:#1a1e33;
  }
  body .list-group-item{
    background-color:#293055;
    color:white;
    border-color:#3e4879;
  }
  
  
  .logo-img{
    max-width: 100px;
    width: 100%;
    height: auto;
    padding:20px;
  
  }


  .qr-box-container .descripcion{

    font-size: x-small;
  }
  .qr-box-container .titulo {
    font-size: small;
    font-weight: bold;
  }

  .folder-box-container {
    cursor: pointer;
  }
  .folder-box-container:hover{
    background-color: #293055;

  }

  .folder-box-container .descripcion{

    font-size: x-small;
  }
  .folder-box-container .titulo {
    font-size: small;
    font-weight: bold;
  }
  .contenedor-imagen-folder{
    
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-top:10px

  }
  .contenedor-imagen-folder img{
    width: 100%;
    max-width: 50px;


  }

  .boton-menu{
    color: white;
    font-weight: bold;
    font-size: 12px;
    background-color: #293055;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 5px;
  }




  /*account*/


  .contenedor-account{
    padding: 20px;
    color:white;
  }
  .contenedor-account h3{
    font-size: 22px;
    font-weight: 0;
  }
  .contenedor-account .input{
    font-size: 13px;
    font-weight: regular;
    padding:5px;
  }
  .contenedor-account .input span{
    padding:10px;
    min-width: 200px;
  }

  .form-contact .input{
    display: flex;

  }
  .form-select{
    cursor: pointer;
  }



  .contenedor-detalle{
    padding: 30px;
    padding-left: 40px;
    color:white;

  }

  .header-no-conected{
    min-height: 4.5rem;
    border-bottom: 1px solid var(--black-secondary);
    background-color: var(--black-primary);
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  .header-no-conected .navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--black-primary)!important;
    color:white!important;
  }
  .header-no-conected .navbar .nav-link{
    color:white!important;
  }


  .contenedor-slider-home{
    background-color: var(--black-primary)!important;
    padding:40px;
    
  }
  .contenedor-slider-home .contenedor-slide-home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding:50px;
  position: relative;
  z-index: 99;
 
  }

  .gradientUp{
    width: 100%;
    max-width: 60px;
    height: 100px;
    background-color: var(--orchid);
    filter: blur(50px);
    position: absolute;
    top: auto;
    bottom:10%;
    left: 45%;
    right: 0%;
    z-index: 1;
  }
  .contenedor-slider-home .contenedor-slide-home img{
    max-width: 300px;
    width: 100%;
    z-index: 99;

 
  }
  .contenedor-texto-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    color:white;
    align-items: center;
  }
  .contenedor-texto-home h1{
    background-image: linear-gradient(300deg, var(--turquoise), var(--orchid));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: 'Poppins', sans-serif;
    font-size: 3.5rem;
  }
  .contenedor-texto-slide{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    color:white;
    align-items: center;
  }
  .contenedor-texto-slide h1{
    background-image: linear-gradient(300deg, var(--turquoise), var(--orchid));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }

  .spacer{
    height: 100px;

  }
  
  .contenedor-texto-home p{
   
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      opacity: 1;
      transform-style: preserve-3d;
  }
  .contenedor-features{
    background-color: var(--black-secondary);
    border-radius: 20px;
    padding:30px;
  }

  .contenedor-features .logo-feat img{
    max-width: 200px;
    width: 100%;
  }
  .contenedor-features .texto-feat{
    padding: 20px;
    color:white;
  }
  .contenedor-features .texto-feat h3{

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
    
  
  }
.contenedor-features .texto-feat p{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #c3c3c3;
}
.contenedor-features{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}
  @media (min-width: 1200px){
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
      justify-content: center;
    }
  
  }

.formulario-samples{
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  border-radius: 30px;
  margin-top: 30px;
  padding: 30px;
  border: solid 1px #5151515c;
}


.boton-login{
  color: white;
  background-color: #111423 !important;
  padding: 13px;
  width: -moz-fit-content;
  width: fit-content;
  /* min-width: 140px; */
  text-align: center;
  -webkit-text-decoration: uppercase;
  text-decoration: uppercase;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px !important;
  cursor: pointer;
}
.boton-login:hover{
  background-color:#3e4879!important;
  color:#0c0e1b;
}

.contenedor-grafico{
  background-color: rgb(252, 252, 252);
  border-radius: 10px;
}

.separador-superior{
  border-top:solid 1px rgb(32, 32, 32);
  padding-top:10px;
}

.centrar{
  margin:0 auto;
}